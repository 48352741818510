import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  Auth as FireBaseAuth,
  User,
  UserCredential,
  signInWithCustomToken,
} from 'firebase/auth';
import { firebase } from 'app/firebase';
import { env } from 'config/env';
import { createDeveloperToken, updateAdminUser } from './graphql';

interface SignInResponse {
  user?: User;
  error?: string;
}

interface RegisterProps {
  firstName: string;
  lastName: string;
  email: string;
  website: string;
  password: string;
}

// https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth
const ERROR_MAP: { [key: string]: string } = {
  base: 'Something is wrong. Please try again later.',

  'auth/Invalid-email': 'Invalid email.',
  'auth/user-disabled': 'User disabled.',
  'auth/user-not-found': 'User not found.',
  'auth/wrong-password': 'Wrong password.',
  'auth/operation-not-allowed': 'Operation not allowed.',
  'auth/email-already-in-use': 'Email already in use.',
  'auth/weak-password': 'Weak password.',
};

class Auth {
  auth: FireBaseAuth;
  googleProvider: GoogleAuthProvider;

  constructor() {
    firebase.initialize();
    this.auth = getAuth(firebase.app);
    this.googleProvider = new GoogleAuthProvider();
  }

  private logError(err: unknown) {
    env.REACT_APP_NODE_ENV !== 'production' && console.error(err);
  }

  // Register with Google account, or sign in if account already exists
  public signInOrRegisterWithGoogle = async (): Promise<SignInResponse> => {
    try {
      const resp = await signInWithPopup(this.auth, this.googleProvider);

      return resp;
    } catch (err) {
      this.logError(err);

      return { error: ERROR_MAP.base };
    }
  };

  public logInWithEmailAndPassword = async (
    email: string,
    password: string,
  ): Promise<SignInResponse> => {
    return await signInWithEmailAndPassword(this.auth, email, password)
      .then(async (resp) => {
        return { user: resp?.user as User };
      })
      .catch((err) => {
        this.logError(err);
        const key = err?.code as string;
        return { error: ERROR_MAP[key] || ERROR_MAP.base };
      });
  };

  public assumeDeveloperIdentity = async (
    developerId: string,
    apiKey: string | undefined,
    userToken?: string | null,
    readOnly = true,
  ): Promise<SignInResponse> => {
    const response = await createDeveloperToken({
      developerId,
      readOnly,
      apiKey,
      userToken,
    });

    const errorMsg = response?.data?.createDeveloperToken?.error;
    const token = response?.data?.createDeveloperToken?.token;

    if (!token) {
      return { error: errorMsg || undefined };
    }

    return await signInWithCustomToken(this.auth, token)
      .then(async (resp) => {
        return { user: resp?.user as User };
      })
      .catch((err) => {
        this.logError(err);
        const key = err?.code as string;
        return { error: ERROR_MAP[key] || ERROR_MAP.base };
      });
  };

  // Create / register a new user that signed up with email/password
  public registerWithEmailAndPassword = async ({
    firstName,
    lastName,
    email,
    website,
    password,
  }: RegisterProps): Promise<SignInResponse> => {
    try {
      // Create new user via Firebase Auth
      const resp = await createUserWithEmailAndPassword(this.auth, email, password);
      const { firestoreUser, user } = await updateFirestoreUser({
        userCred: resp,
        firstName,
        lastName,
        website,
      });

      if (!firestoreUser) {
        throw new Error('Failed to create user');
      }

      return { user };
    } catch (err) {
      const error = err as unknown as { code?: string };
      const key = error?.code as string;
      this.logError(error);
      return { error: ERROR_MAP[key] || ERROR_MAP.base };
    }
  };

  public sendPasswordReset = async (email: string): Promise<SignInResponse> => {
    return await sendPasswordResetEmail(this.auth, email)
      .then(() => {
        alert('Password reset link has been sent');
        return {};
      })
      .catch((err) => {
        this.logError(err);
        const key = err?.code as string;
        return { error: ERROR_MAP[key] || ERROR_MAP.base };
      });
  };

  public logout = () => {
    signOut(this.auth);
  };
}

const authAPI = new Auth();

export default authAPI;
async function updateFirestoreUser({
  userCred,
  firstName,
  lastName,
  website,
}: {
  userCred: UserCredential;
  firstName?: string;
  lastName?: string;
  website?: string;
}) {
  const user = userCred.user;
  const displayName = firstName && lastName ? [firstName, lastName].join(' ') : user.displayName;
  const userToken = await user.getIdToken();
  if (!user.email) {
    throw new Error('User missing email');
  }

  // Create a Firestore record with additional user details
  const mutation = await updateAdminUser(
    {
      firstName: firstName ?? '',
      lastName: lastName ?? '',
      displayName: displayName ?? '',
      email: user.email,
      website: website ?? '',
    },
    undefined,
    userToken,
  );

  const firestoreUser = mutation?.data?.updateUser?.data?.user;
  return { firestoreUser, userToken, user };
}
